import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { isChrome, isEdge, isDesktop } from 'core/utils/helpers/browser';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';
import {
  selectSiteUrl, selectDataPrivacyPageEnabled, selectClientHostedDPSRedirectURL,
  selectTermsAndConditionsURL, selectPrivacyPolicyUrl, selectToolbarConfig, selectBrandUrl,
} from 'core/selectors/app';
import { useIsButtonInstalled } from 'core/hooks/useIsButtonInstalled';
import { useGetNavigation } from 'core/modules/Navigation/Nav/hooks';
import { useGetCategories } from 'core/modules/Navigation/NavCategories/hooks/useGetCategories';
import { selectUserIsIdentified } from 'core/selectors/user';
import MainNavItem from 'core/modules/Navigation/NavPartials/MainNavItem/MainNavItem';
import NavItem from 'core/modules/Navigation/NavPartials/NavItem/NavItem';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import './Navigation.scss';

function Navigation() {
  const siteUrl = useSelector(selectSiteUrl);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const enableDonotSharePage = useSelector(selectDataPrivacyPageEnabled);
  const clientHostedDPSRedirectURL = useSelector(selectClientHostedDPSRedirectURL);
  const termsAndConditionsURL = useSelector(selectTermsAndConditionsURL);
  const privacyPolicyUrl = useSelector(selectPrivacyPolicyUrl);
  const toolbarConfig = useSelector(selectToolbarConfig);
  const brandUrl = useSelector(selectBrandUrl);
  const { navItemsFeatured, navItemsNonFeatured } = useGetNavigation();
  const { categories } = useGetCategories();
  const { isEnabledChrome, chromeDownloadUrl } = toolbarConfig;
  const isButtonInstalled = useIsButtonInstalled();
  const shouldShowButtonLink = !isButtonInstalled && isEnabledChrome && chromeDownloadUrl && isDesktop
    && (isChrome || isEdge);
  const gunSafetyItem = { title: 'Gun Safety', url: `${siteUrl}/sr____.htm?keywords=gun%20safes` };
  const topNavItems =
    [...navItemsFeatured.slice(0, 1), gunSafetyItem, ...navItemsFeatured.slice(1)];

  return (
    <div className="mn_navBar">
      <div className="mn_mainNav mn_contentSection">
        <MainNavItem title="Home" className="mn_active" url={`${siteUrl}`} />

        {categories &&
          <MainNavItem className="mn_navCategories" title="Categories">
            {categories.map(category => (
              <NavItem
                key={category.id}
                title={category.name}
                label={`Visit the ${category.name} page`}
                url={`${siteUrl}/${category.searchFriendlyName}/bc_${category.id}___.htm`}
              />
            ))}
          </MainNavItem>
        }

        <MainNavItem title="Offers">
          {navItemsFeatured && navItemsFeatured.map(navItem => (
            <NavItem key={navItem.title.toString()} title={navItem.title} url={navItem.url} hideExtraRewardsLabel />
          ))}
          {navItemsNonFeatured && navItemsNonFeatured.map(navItem => (
            <NavItem key={navItem.title.toString()} title={navItem.title} url={navItem.url} hideExtraRewardsLabel />
          ))}
          <NavItem title="All Online Offers" url={`${siteUrl}/sp____.htm`} />
          <NavItem title="Free shipping" url={`${siteUrl}/fs____Free%20Shipping.htm`} />
        </MainNavItem>

        <MainNavItem title="Stores" url={`${siteUrl}/b____.htm`} />

        {topNavItems && topNavItems.map(navItem => (
          <Fragment key={navItem.title.toString()}>
            <MainNavItem title={navItem.title} url={navItem.url} />
          </Fragment>
        ))}

        <MainNavItem title="How It Works" url={`${siteUrl}/hiw____.htm`} />
        <MainNavItem title="Favorites" url={`${siteUrl}/favorites____.htm`} />
        <MainNavItem title="My Account" url={`${siteUrl}/ai____.htm`} />
        <MainNavItem title="Offers For You" url={`${siteUrl}/foryou____.htm`} />
        <MainNavItem title="FAQs" url={`${siteUrl}/faq____.htm`} />
        <MainNavItem title="Contact Us" url={`${siteUrl}/contact____.htm`} />
        <MainNavItem
          {...addExternalAttributesIfNeeded(brandUrl, siteUrl)}
          title="USAA.com"
          url={brandUrl}
          className="mn_externalLink mn_active mn_usaaSVGIconExternalLinkGrey"
        />

        <MainNavItem
          {...addExternalAttributesIfNeeded(privacyPolicyUrl, siteUrl)}
          title="Privacy Policy"
          url={`${privacyPolicyUrl}`}
        />
        <MainNavItem
          {...addExternalAttributesIfNeeded(termsAndConditionsURL, siteUrl)}
          title="Terms"
          url={`${termsAndConditionsURL}`}
        />
        {userIsIdentified && (
          <ShowOnMQ m l>
            <MainNavItem title="Log Out" url={`${siteUrl}/logoutUser.php?lUrl=/`} />
          </ShowOnMQ>
        )}
        {enableDonotSharePage && !userIsIdentified && (
        <MainNavItem
          title="Privacy preferences"
          url={`${siteUrl}/dps____.htm`}
        />
        )}
        {enableDonotSharePage && userIsIdentified && clientHostedDPSRedirectURL && (
          <MainNavItem
            title="Privacy preferences"
            url={clientHostedDPSRedirectURL}
            target="_blank"
          />
        )}
        {shouldShowButtonLink && (
          <MainNavItem newPillEnabled title="Button" url={`${siteUrl}/button`} />
        )}
      </div>
    </div>
  );
}

export default Navigation;
